import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import sessionAPI from "./sessions.api";

export const getSession = createAsyncThunk(
  "sessions/getSession",
  async ({ id }, thunkAPI) => {
    try {
      const response = await sessionAPI.getSession(id);
      if (response.error) {
        return thunkAPI.rejectWithValue(response.error);
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const postSession = createAsyncThunk(
  "sessions/postSession",
  async ({ payload }, thunkAPI) => {
    try {
      const response = await sessionAPI.postSession("new", {
        session: payload,
      });
      if (response.error) {
        return thunkAPI.rejectWithValue(response.error);
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const putSession = createAsyncThunk(
  "sessions/putSession",
  async ({ id, payload }, thunkAPI) => {
    try {
      const response = await sessionAPI.putSession(id, { session: payload });
      if (response.error) {
        return thunkAPI.rejectWithValue(response.error);
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteSession = createAsyncThunk(
  "sessions/deleteSession",
  async ({ id }, thunkAPI) => {
    try {
      await sessionAPI.deleteSession(id);
      return { id };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const CRUD_THUNKS = {
  getSession,
  postSession,
  putSession,
  deleteSession,
};

const sessionsAdapter = createEntityAdapter({
  selectId: (session) => session._id,
});

const pendingOrRejected = (crud, thunk) => {
  crud[thunk.pending] = (state) => {
    state.loading = "pending";
  };
  crud[thunk.rejected] = (state, action) => {
    state.loading = "rejected";
    state.error = action.payload;
  };
  return crud;
};


const sessionsSlice = createSlice({
  name: "sessions",
  initialState: sessionsAdapter.getInitialState({
    loading: "idle",
    ids: [],
    entities: {},
    currentRequestId: undefined,
    error: null,
  }),
  reducers: {},
  extraReducers: {
    // Create generic pending and rejected reducers for CRUD THUNKS.
    ...Object.values(CRUD_THUNKS).reduce(pendingOrRejected, {}),
    // Create generic pending and rejected reducers for GET ALL THUNKS.
    [getSession.fulfilled]: (state, action) => {
      state.loading = "fulfilled";
      sessionsAdapter.upsertOne(state, action.payload.session);
    },
    [postSession.fulfilled]: (state, action) => {
      state.loading = "fulfilled";
      sessionsAdapter.addOne(state, action.paylod.session);
    },
    [putSession.fulfilled]: (state, action) => {
      state.loading = "fulfilled";
      sessionsAdapter.upsertOne(state, action.payload.session);
    },
    [deleteSession.fulfilled]: (state, action) => {
      state.loading = "fulfilled";
      sessionsAdapter.removeOne(state, action.payload.id);
    },
  },
});

export default sessionsSlice.reducer;
