import { MentorSessionQueryResult } from "entities/MentorSession";
import MentorSessionCard from "./MentorSessionCard";
import styles from "./MentorSessions.module.css";

interface UpcomingSessionProps {
  session: MentorSessionQueryResult;
}

const UpcomingSession = ({ session }: UpcomingSessionProps) => {

  const joinSession = () =>
    session.videoRoomLink && window.open(session.videoRoomLink, "_blank");

  const rescheduleSession = () => {
    window.open("mailto:team@mentorpass.co?subject=Reschedule%20Session&body=Hi%20Team%2C%20I'd%20like%20to%20reschedule%20a%20session.", "_blank")
  }

  const cancelSession = () => {
    window.open("mailto:team@mentorpass.co?subject=Cancel%20Session&body=I'd%20like%20to%20cancel%20a%20session.", "_blank")
  }

  return (
    <MentorSessionCard
      session={session}
      actions={
        <div className={styles.button_container}>
          <button className={styles.blue_button} onClick={joinSession}>Join Session</button>
          <button className={styles.black_button} onClick={rescheduleSession}>Reschedule Session</button>
          <button className={styles.red_button} onClick={cancelSession}>Cancel Session</button>
        </div>
      }
    />
  );
};

export default UpcomingSession;
