import React, { useState } from "react";
import moment from "moment";
import Styles from "./MenteeSessions.module.css";
import {
  UpBlueChevron,
  BlueChevron,
  CalendarIcon,
  CancelCircle,
  TickSign,
  DollarIcon,
} from "../../Icons";
import AvatarPlaceholder from "../../../images/avatar-placeholder.gif";
import { ALLOWED_STATUS } from "entities/Session";
import { useAlert } from "@blaumaus/react-alert";
import { useNavigate } from "react-router-dom";
import { MenteeSessionQueryResult } from "entities/MenteeSession";


interface SessionCardProps {
  session: MenteeSessionQueryResult;
}

const MenteeSessionCard = ({ session }: SessionCardProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();

  const joinCall = () => {
    // SEGEMENT TODO - track join call
    if (session && session.videoRoomLink) {
      window.open(session.videoRoomLink, "_blank");
    } else {
      alert.error("Your session dosen't have a video room. Contact support");
    }
  };

  const openDetail = () => setShowDetails(!showDetails);


  const goToMentorProfile = () => navigate(`/profile/${session.mentor_profile.slug}`);

  return (
    <div className={Styles.sessionCardContainer}>
      <div className={Styles.sessionCard}>
        <img
          src={
            (session.mentor_profile && session.mentor_profile.profileImage.url) ||
            AvatarPlaceholder
          }
          alt="card-profile"
          width="120px"
          height="120px"
          style={{ cursor: "pointer" }}
          onClick={goToMentorProfile}
        />
        <div className={Styles.cardInfo} onClick={openDetail}>
          <div className={Styles.detail}>
            <div className={Styles.basicDetail}>
              <h3>{`${session.mentor_profile.given_name} ${session.mentor_profile.family_name}`}</h3>
              <p className={Styles.objective}>{session.mentor_profile.pitch}</p>
            </div>
            <button
              className={Styles.detailBtn}
              onClick={() => setShowDetails(!showDetails)}
            >
              Detail
              {showDetails ? (
                <img
                  width="15px"
                  height="15px"
                  src={UpBlueChevron}
                  alt="up-blue-chevron"
                />
              ) : (
                <img
                  width="15px"
                  height="15px"
                  src={BlueChevron}
                  alt="blue-chevron"
                />
              )}
            </button>
          </div>

          <div className={Styles.infoSection}>
            <div className={Styles.dateInfo}>
              <img
                width="15px"
                height="15px"
                src={CalendarIcon}
                alt="calendar-icon"
              />
              <p>{moment(session.slot).format("llll")}</p>
            </div>
            {session.price && (
              <div className={Styles.dateInfo}>
                <img
                  width="15px"
                  height="15px"
                  src={DollarIcon}
                  alt="calendar-icon"
                />
                <p>{Intl.NumberFormat('en-US').format(session.price)}</p>
              </div>
            )}
            {/* Session Canceled */}
            {[ALLOWED_STATUS.CANCELED, ALLOWED_STATUS.DECLINED, ALLOWED_STATUS.EXPIRED].includes(session.status) && (
              <div className={Styles.canceledTag}>
                <img width="12px" height="12px" src={CancelCircle} alt="" />
                <p>Session {session.status}</p>
              </div>
            )}
            {/* Session Complete */}
            {session.status === ALLOWED_STATUS.COMPLETED && (
              <div className={Styles.completedTag}>
                <img width="12px" height="12px" src={TickSign} alt="" />
                <p>Session Complete - {session.duration} min</p>
              </div>
            )}
          </div>
        </div>
        <button
          className={Styles.mobileDetailBtn}
          onClick={() => setShowDetails(!showDetails)}
        >
          Detail
          {showDetails ? (
            <img
              width="15px"
              height="15px"
              src={UpBlueChevron}
              alt="up-blue-chevron"
            />
          ) : (
            <img
              width="15px"
              height="15px"
              src={BlueChevron}
              alt="blue-chevron"
            />
          )}
        </button>
      </div>
      {showDetails && <hr className={Styles.lineSeparator} />}
      <div
        className={`${Styles.sessionDetails} ${showDetails && Styles.slideDown
          }`}
        style={{
          display: showDetails ? "block" : "none",
        }}
      >
        <p className={Styles.fieldTitle}>AGENDA</p>
        <p className={Styles.fieldDetail}>{session.description}</p>
        {session.okr && session.okr.title && (
          <>
            <p className={Styles.fieldTitle}>OKR</p>
            <p className={Styles.okrLink}>{session.okr.title}</p>
          </>
        )}
        <div className={Styles.action}>
          <div className={Styles.action}>
            {session.status === ALLOWED_STATUS.COMPLETED && !session.mentee_review && (
              <button
                onClick={() => navigate(`/session-review/${session._id}`)}
              >
                Review Session
              </button>
            )}
            {session.status === ALLOWED_STATUS.UPCOMING && (
              <>
                <button onClick={joinCall}>Join call</button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenteeSessionCard;
