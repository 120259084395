import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "@blaumaus/react-alert";
import DropdownSelector from "components/Atoms/DropdownSelector";
import timezones from "constants/timezones";
import useUpdateUserMetadata from "hooks/user/useUpdateUserMetadata";
import processAuthClaims from "utils/processAuthClaims";
import usePasswordReset from "hooks/user/usePasswordReset";
import Button from "components/Atoms/Button";
import styles from "./UserSettings.module.css";

interface UserSettingsProps {
  id: string;
}

const UserSettings = ({ id }: UserSettingsProps) => {
  const { user } = useAuth0();
  const user_data = processAuthClaims(user);
  console.log(user_data);
  const alert = useAlert();
  const onSuccess = () => alert.success("Timezone updated");
  const { update: updateUserMetadata } = useUpdateUserMetadata({ onSuccess });
  const { is_loading, redirectToPasswordReset } = usePasswordReset();

  return (
    <div className="section" id={id} style={{ marginBottom: "24px" }}>
      <div className="section-content">
        <div className="section-header">
          <h4>Timezone</h4>
        </div>
        <div>
          <DropdownSelector
            id=""
            label=""
            value=""
            placeholder={user_data.timezone}
            options={timezones}
            onSelect={(data) => updateUserMetadata({ timezone: data.utc[0] })}
            dark
            outline={false}
          />
        </div>
      </div>
      <div className="section-content">
        <div className="section-header">
          <h4>Password</h4>
        </div>
        <div>
          {user_data.connection_type === "Username-Password-Authentication" ? (
            <>
              <p style={{ paddingBottom: "12px" }}>
                Clicking the button below will redirect you to securely reset
                your password.
              </p>
              <Button
                variant="dark"
                loading={is_loading}
                onClick={redirectToPasswordReset}
                children="Reset Password"
                style={{ width: "100%" }}
              />
            </>
          ) : (
            <>
              <p>
                Password reset is not available for customers using social
                logins.
                <br />
                If you're experiencing account issues please contact{" "}
                <a href="mailto:info@mentorpass.co" className={styles.link}>
                  info@mentorpass.co
                </a>
                .
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
