import { MouseEventHandler, useEffect, useRef, useState } from "react";
import styles from "./VideoPlayer.module.css";
import { useParams } from "react-router-dom";
import Transcript, {
  Phrase,
  Word,
} from "./components/VideoTranscript/VideoTranscript.component";
import Header from "components/Organisms/Header";
import { API_URL, createHeaders } from "utils/api";
import { Video } from "components/Molecules/VideoCard/VideoCard.component";
import { ThreeDots } from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";
import meta from "components/Icons/Icons.stories";

type VideoMetadata = Video & { token: string };

const processWords = (words: [Word]): Phrase[] => {
  let phrases: Phrase[] = [[]];
  let previous_word: Word = [0, 0, ""];
  words.forEach((word) => {
    const phrase_length = word[0] - previous_word[1];
    if (phrase_length < 0.4) {
      phrases[phrases.length - 1].push(word);
    } else {
      phrases.push([word]);
    }
    previous_word = word;
  });
  return phrases;
};

const VideoPlayerScreen = () => {
  // Get ID from URL
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams<{ recording_id: string }>();
  const player_ref = useRef(null);
  // session_id from url params
  const [metadata, setMetadata] = useState<VideoMetadata | null>(null);
  const [timestamp, setCurrentTimestamp] = useState<number>(0);
  const [phrases, setPhrases] = useState<Phrase[]>();

  const goTo: MouseEventHandler<HTMLSpanElement> = (event) => {
    if (player_ref.current) {
      //@ts-ignore
      const start = event.target.dataset.start as string;
      //@ts-ignore
      player_ref.current.seek(Number.parseFloat(start));
      //@ts-ignore
      player.play();
    }
  };

  useEffect(() => {
    // get the session data + video_id
    const getVideo = () => {
      getAccessTokenSilently().then((token) =>
        fetch(`${API_URL}/session/recording/${params.recording_id}`, {
          method: "GET",
          mode: "cors",
          headers: createHeaders(token),
        })
          .then((response) => response.json())
          .then((video: VideoMetadata) => {
            setMetadata(video);
          })
          .catch((error) => console.error(error))
      );
    };
    getVideo();
  }, []);

  useEffect(() => {
    if (!!metadata) {
      //@ts-ignore
      player_ref.current = window.MusePlayer({
        container: "#video_player",
        video: metadata.video_id,
        password: metadata.token,
        logo: "https://storage.googleapis.com/marketing-assets.mentorpass.co/app-assets/mentorpass-icon.png",
        sizing: "fill",
        width: "parent",
        title: false,
        // css: ".cover.logo-custom .player-logo-custom.logo-cover img { height: 32px }",
      });
      //@ts-ignore
      player_ref.current.on("timeupdate", (time: number) => {
        setCurrentTimestamp(time);
        //@ts-ignore
        document.querySelectorAll(".word").forEach(function (word) {
          const start = (word as HTMLElement).dataset.start as string;
          const end = (word as HTMLElement).dataset.end as string;
          if (
            Number.parseFloat(start) <= time &&
            Number.parseFloat(end) > time
          ) {
            word.classList.add("highlight");
            word.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "end",
            });
          } else if (word.classList.contains("highlight")) {
            word.classList.remove("highlight");
          }
        });
      });

      fetch("https://muse.ai/api/files/i/speech/" + metadata.video_id, {
        mode: "cors",
      })
        .then((response) => response.json())
        .then((words) => {
          const new_phrases = processWords(words);
          setPhrases(new_phrases);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [metadata]);

  const downloadVideoFile = async () => {
    try {
      if (!metadata) {
        throw new Error("The video isn't ready to download yet.");
      }
      const a = document.createElement("a");
      a.href = metadata.download_link;
      a.download = `${metadata?.title}.mp4`; // Replace with the desired file name
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Download failed:", error);
      alert("Download failed: " + String(error.message));
    }
  };

  return (
    <>
      <Header>
        <h3 className={styles.title}>Session Recording - {metadata?.title}</h3>
      </Header>
      <div className={styles.container}>
        <div className={styles.video_container}>
          <div className={styles.player_container}>
            {!metadata && (
              <div className={styles.loading_container}>
                <ThreeDots
                  wrapperStyle={{ margin: "auto", display: "block" }}
                  color="#FFF"
                  height={18}
                  width={24}
                />
              </div>
            )}
            <div id="video_player" className={styles.video_player} />
          </div>
          <div className={styles.description_container}>
            <div>
              <h3>{metadata?.title}</h3>
              <p>{metadata?.description}</p>
            </div>
            <div>
              <button
                onClick={downloadVideoFile}
                className={styles.download_btn}
              >
                Download
              </button>
            </div>
          </div>
        </div>
        <div className={styles.transcript_container}>
          <h3 style={{ position: "relative", top: "102px", left: "24px" }}>
            Transcript
          </h3>
          <Transcript
            current_video_timestamp={timestamp}
            phrases={phrases || []}
            goTo={goTo}
          />
        </div>
      </div>
    </>
  );
};

export default VideoPlayerScreen;
