import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./SessionSelector.module.css";
import { CalendarIcon } from "../../../../../../components/Icons";

interface SesssionSelectorProps {
  selected_date?: string;
  proposed_datetimes: string[];
  duration: number;
  availability: any[];
  onClickTimeslot: (date: string) => void;
  selectAllTimeSlot: () => void;
  clearAllTimeSlot: () => void;
}

export const SessionSelector = ({
  selected_date,
  proposed_datetimes,
  duration,
  availability,
  onClickTimeslot,
  selectAllTimeSlot,
  clearAllTimeSlot,
}: SesssionSelectorProps) => {
  const handleSelect = (datetime: string) => onClickTimeslot(datetime);
  const date = moment(selected_date);
  return (
    <div className={styles.container}>
      <h4>When do you want to talk?</h4>
      {selected_date && (
        <h5 style={{ margin: "0px 0px 8px 0px" }}>{date.format("LL")}</h5>
      )}
      <div className={styles.availability}>
        {availability.length > 0 ? (
          availability.map((slot, index) => (
            <div
              key={`av-${index}`}
              className={
                proposed_datetimes.includes(slot.toISOString())
                  ? [styles.slot, styles.slotActive].join(" ")
                  : styles.slot
              }
              onClick={() => handleSelect(slot.toISOString())}
            >
              {slot.format("LT")} -{" "}
              {slot.clone().add("minutes", duration).format("LT")}
            </div>
          ))
        ) : (
          <div className={styles.noSlotsContainer}>
            <img className={styles.calIcon} src={CalendarIcon} />
            <p
              style={{
                margin: "auto",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Select an available date to view available times.
            </p>
          </div>
        )}
      </div>
      <span className={styles.select_clear_container}>
        <p className={styles.clear_select_button} onClick={selectAllTimeSlot}>
          select all
        </p>{" "}
        |{" "}
        <p className={styles.clear_select_button} onClick={clearAllTimeSlot}>
          clear all
        </p>
      </span>
    </div>
  );
};

export default SessionSelector;
