import { useState, useEffect, ChangeEvent } from "react";
import LargeRadioButtons from "components/Molecules/LargeRadioButtons";
import InfoBox from "components/Molecules/InfoBox";
import useMyProfile from "hooks/useMyProfile";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import {
  AvailableDay,
  AvailabilityConfiguration,
  defaultAvailabilityConfiguration,
  WeekDay,
  isMentor
} from "entities/Mentor";

import { ModalNames, useModal } from "components/Organisms/modals/ModalProvider";

const DAYS = [
  { title: "Sunday", id: WeekDay.SUNDAY },
  { title: "Monday", id: WeekDay.MONDAY },
  { title: "Tuesday", id: WeekDay.TUESDAY },
  { title: "Wednesday", id: WeekDay.WEDNESDAY },
  { title: "Thursday", id: WeekDay.THURSDAY },
  { title: "Friday", id: WeekDay.FRIDAY },
  { title: "Saturday", id: WeekDay.SATURDAY },
];

interface DayProps {
  title: string;
  hours: AvailableDay[];
  launchEditModal: () => void;
}

const Day = ({ title, hours, launchEditModal }: DayProps) => (
  <>
    <div className="info-box">
      <div className="info-section">
        <div className="bold-header">
          <p>{title}</p>
        </div>
        {hours.length > 0 ? (
          hours.map((h, i) => (
            <p key={i}>
              {h.start.title} - {h.end.title}
            </p>
          ))
        ) : (
          <p className="bold-font">No times added</p>
        )}
      </div>

      <div className="action">
        <button className="ellipse-hollow" onClick={launchEditModal}>
          Edit Times
        </button>
      </div>
    </div>
    <hr className="border-black-availablility" />
  </>
);

const Availability = ({ id }: { id: string }) => {
  const { profile } = useMyProfile();
  const { update } = useUpdateMentor();
  const { openModal } = useModal();
  const [availability_details, setAvailabilityDetails] =
    useState<AvailabilityConfiguration>(defaultAvailabilityConfiguration);
  const [weekly_limit, setWeeklyLimit] = useState<number>(
    defaultAvailabilityConfiguration.weeklyLimit
  );
  const [edit_weekly_limit, setEditWeeklyLimit] = useState<boolean>(false);

  if(!isMentor(profile)) {
    throw Error("Only mentors can update availability.")
  }

  useEffect(() => {
    setAvailabilityDetails({
      ...availability_details,
      ...profile.availabilityDetails,
    });
    setWeeklyLimit(
      profile.availabilityDetails.weeklyLimit ||
        defaultAvailabilityConfiguration.weeklyLimit
    );
  }, [profile]);

  const toggleSessionLengthChoice = (value: "halfHour" | "hour") => {
    const new_availability_config = {
      ...availability_details,
      [value]: !availability_details[value],
    };
    setAvailabilityDetails(new_availability_config);
    update({ availabilityDetails: new_availability_config });
  };

  const updateWeeklyLimit = () => {
    const new_availability_config = {
      ...availability_details,
      weeklyLimit: weekly_limit,
    };
    setAvailabilityDetails(new_availability_config);
    update({ availabilityDetails: new_availability_config });
  };

  const launchSetHoursModal = (data: WeekDay) =>
    openModal({ name: ModalNames.EDIT_AVAILABILITY, data });

  return (
    <div className="section" id={id}>
      <div className="section-content">
        <div className="section-header">
          <h3>Availability</h3>
        </div>
        <p className="bold-header" style={{ marginBottom: "6px" }}>
          <span>Available Session Lengths</span>
        </p>
        <div className="booking-time">
          <LargeRadioButtons
            checked={availability_details.halfHour}
            name="halfHour"
            value={"30"}
            valueLabel="30 minutes"
            helperText={`You ${
              availability_details.halfHour ? "are" : "are not"
            }  offering 30 minute sessions`}
            onRadioBtnClick={() => toggleSessionLengthChoice("halfHour")}
          />
          <LargeRadioButtons
            name="hour"
            checked={availability_details.hour}
            value={"60"}
            valueLabel="60 minutes"
            helperText={`You ${
              availability_details.hour ? "are" : "are not"
            }  offering 60 minute sessions`}
            onRadioBtnClick={() => toggleSessionLengthChoice("hour")}
          />
        </div>
        {DAYS.map((d) => (
          <Day
            key={d.id}
            title={d.title}
            hours={profile?.availability[d.id] || []}
            launchEditModal={() => launchSetHoursModal(d.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default Availability;
