/* eslint-disable indent */
import styles from "./DurationSelection.module.css";
import LargeRadioButtons from "components/Molecules/LargeRadioButtons";

export interface DurationSelectionProps {
  timezone?: string;
  hourly_token_rate: number;
  durations_available: {
    half_hour: boolean;
    hour: boolean;
  };
  balance: number;
  session_length: number;
  setSessionDuration: (duration: number) => void;
}

const DurationSelection = ({
  hourly_token_rate,
  durations_available,
  balance,
  session_length,
  setSessionDuration,
}: DurationSelectionProps) => {
  return (
    <div className={styles.timePicker}>
      <h4>How long do you want to talk?</h4>
      <p
        style={{
          fontSize: "12px",
          margin: "8px 0px",
        }}
      >
        {typeof balance !== "number"
          ? "Loading account balance..."
          : `You have ${balance} credits remaining in your account.`}
      </p>
      <div className={styles.durationSelection}>
        {durations_available.half_hour && (
          <LargeRadioButtons
            checked={session_length === 30}
            name="halfHour"
            value={"30"}
            valueLabel="30 minutes"
            helperText={`This will cost ${
              hourly_token_rate / 2 || "Data Not Available"
            } credits`}
            onRadioBtnClick={() => setSessionDuration(30)}
          />
        )}
        {durations_available.hour && durations_available.half_hour && (
          <div style={{ width: "24px" }} />
        )}
        {durations_available.hour && (
          <LargeRadioButtons
            checked={session_length === 60}
            name="hour"
            value={"60"}
            valueLabel="60 minutes"
            helperText={`This will cost ${
              hourly_token_rate || "Data Not Available"
            } credits`}
            onRadioBtnClick={() => setSessionDuration(60)}
          />
        )}
      </div>
    </div>
  );
};

export default DurationSelection;
