import React from "react";
import DurationSelectionComponent from "./DurationSelection.component";
import { useDispatch, useSelector } from "react-redux";
import { setBookingDuration } from "../../../../../../store/dux/booking/booking.actions";
import useBusiness from "hooks/mentee/useBusiness";
import useMentor from "hooks/useMentor";
import { Grid } from "react-loader-spinner";
import useMyProfile from "hooks/useMyProfile";

const DurationSelection = ({ mentor_slug }: { mentor_slug: string }) => {
	const dispatch = useDispatch();

	const { mentor } = useMentor({ slug: mentor_slug });

	const durations_available = {
		half_hour: !!mentor?.availabilityDetails?.halfHour,
		hour: !!mentor?.availabilityDetails?.hour,
	}

	const hourly_token_rate = mentor?.hourlyRate.tokens;

	const { business } = useBusiness();

	const session_length: number = useSelector(
		(state: any) => state.booking.duration
	);

	const setSessionDuration = (duration: number) => {
		dispatch(setBookingDuration(duration));
		// scroll to booking section of the form
		const booking = window.document.getElementById("booking-date");
		booking?.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>{mentor && hourly_token_rate && business ? (
			<DurationSelectionComponent
				hourly_token_rate={hourly_token_rate}
				durations_available={durations_available}
				balance={business?.balance}
				session_length={session_length}
				setSessionDuration={setSessionDuration}
			/>) : (
			<div style={{ minHeight: "220px", paddingTop: "15%" }} >
				<Grid wrapperStyle={{ margin: "auto", display: "block" }}
					color="var(--primary-colour)"
					height={32}
					width={32}
				/>
			</div>)}
		</>
	);
};

export default DurationSelection;
