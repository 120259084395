import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AcceptSessionComponent from "./AcceptSession.component";
import useSession from "hooks/useSession";
//@ts-ignore
import { useAlert } from "@blaumaus/react-alert";
import { useAuth0 } from "@auth0/auth0-react";
import processAuthClaims from "utils/processAuthClaims";
import useAcceptSession from "hooks/mentor/session/useAcceptSession";
import useDeclineSession from "hooks/mentor/session/useDeclineSession";

const AcceptSession = () => {
    const { session_id, slot } = useParams();
    const alert = useAlert();

    const { user } = useAuth0();
    const { accept, is_loading: accept_session_loading } = useAcceptSession();
    const { decline, is_loading: decline_session_loading } = useDeclineSession();
    const { timezone } = processAuthClaims(user);

    if (!timezone) {
        alert.error(
            "Please set your timezone in your profile settings before accepting a session!"
        );
        throw Error("No timezone set");
    }

    if (!session_id) {
        alert.error("No session id set!");
        throw Error("No session id set");
    }

    const {
        session,
        error: error_fetching_session,
        refetch,
        is_loading,
    } = useSession({ id: session_id });

    const handleAcceptSession = async (slot: string) => {
        await accept(session_id, slot, () => {
            refetch();
        });
    };

    const handleDeclineSession = async () => {
        await decline(session_id, "", () => {
            refetch();
        });
    };

    useEffect(() => {
        if (!!error_fetching_session) {
            alert.error(
                "Something went wrong getting the session! Please contact support."
            );
        }
    }, [error_fetching_session]);

    return (
        <>
            {is_loading || !session ? (
                <div>Loading...</div>
            ) : (
                <AcceptSessionComponent
                    profile={session && session.from}
                    session={{
                        ...session,
                        slot: slot,
                    }}
                    mentor_timezone={timezone}
                    requires_submit={session && session.status !== "upcoming"}
                    acceptSession={handleAcceptSession}
                    declineSession={handleDeclineSession}
                    rescheduleSession={() => {}}
                    accept_session_loading={accept_session_loading}
                    decline_session_loading={decline_session_loading}
                />
            )}
        </>
    );
};

export default AcceptSession;
