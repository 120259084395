import { useState } from "react";
import AvatarPlaceholder from "../../../images/avatar-placeholder.gif";
import { MentorSessionQueryResult } from "entities/MentorSession";
import moment from "moment";
import styles from "./MentorSessions.module.css";
import { CancelCircle, TickSign, UpBlueChevron } from "components/Icons";
import Socials from "./Socials";
import { ALLOWED_STATUS } from "entities/Session";
interface SessionCardProps {
  session: MentorSessionQueryResult;
  actions: React.ReactNode;
}

const SessionCard: React.FC<SessionCardProps> = ({ session, actions }) => {
  const is_mobile = window.innerWidth < 576;

  const [show_details, setShowDetails] = useState(is_mobile);

  const toggleShowDetails = () => setShowDetails(!show_details);

  const formatSessionDuration = (duration: number) => {
    if (!duration) return "";
    return {
      30: "30 min session",
      60: "1 hour session",
    }[duration];
  };

  const makeName = () => {
    const { given_name, family_name } = session.mentee_profile;
    if (!given_name && !family_name) {
      return "Anonymous";
    }
    return `${given_name || ""} ${family_name || ""}`.trim();
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img
          className={styles.avatar}
          alt="avatar"
          src={session.mentee_profile?.profileImage?.url || AvatarPlaceholder}
        />
        <div className={styles.mentor_info}>
          <h1>{makeName()}</h1>
          <div className={styles.session_details}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 0C3.44772 0 3 0.447715 3 1V2H2C0.895431 2 0 2.89543 0 4V5V7V14C0 15.1046 0.89543 16 2 16H12C13.1046 16 14 15.1046 14 14V7V5V4C14 2.89543 13.1046 2 12 2H11V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V2H5V1C5 0.447715 4.55228 0 4 0ZM12 7H2L2 14H12V7ZM9 9H5V12H9V9Z"
                  fill="#5440F0"
                />
              </svg>
              <p className={styles.session_details_text}>
                {moment(session.slot).format("llll")} -{" "}
                {formatSessionDuration(session.duration)}
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM8.1929 11.7251H7.01774V10.6497C5.93126 10.4723 5.18847 9.80709 5 8.74279H6.37472C6.56319 9.3082 7.01774 9.56319 7.63858 9.56319C8.27051 9.56319 8.66962 9.27494 8.66962 8.77605C8.66962 8.36585 8.43681 8.15521 7.86031 8.02217L6.77384 7.7561C5.87583 7.54545 5.15521 7.04656 5.15521 5.949C5.15521 4.90687 5.898 4.15299 7.01774 4.00887V3H8.1929V4.05321C9.16851 4.27494 9.74501 4.92905 9.93348 5.83814H8.61419C8.47007 5.41685 8.09313 5.09534 7.43902 5.09534C6.85144 5.09534 6.4745 5.39468 6.4745 5.84922C6.4745 6.22616 6.7184 6.43681 7.18404 6.55876L8.33703 6.83592C9.36807 7.09091 10 7.70066 10 8.69845C10 9.77384 9.25721 10.4723 8.1929 10.6497V11.7251Z"
                  fill="#5440F0"
                />
              </svg>
              <p className={styles.session_details_text}>${session.price}</p>
            </div>
            {/* Session Canceled */}
            {[
              ALLOWED_STATUS.CANCELED,
              ALLOWED_STATUS.DECLINED,
              ALLOWED_STATUS.EXPIRED,
            ].includes(session.status) && (
              <div className={styles.canceledTag}>
                <img width="12px" height="12px" src={CancelCircle} alt="" />
                <p>Session {session.status}</p>
              </div>
            )}
            {/* Session Complete */}
            {session.status === ALLOWED_STATUS.COMPLETED && (
              <div className={styles.completedTag}>
                <img width="12px" height="12px" src={TickSign} alt="" />
                <p>Session Complete - {session.duration} min</p>
              </div>
            )}
            {![
              ALLOWED_STATUS.CANCELED,
              ALLOWED_STATUS.DECLINED,
              ALLOWED_STATUS.EXPIRED,
              ALLOWED_STATUS.COMPLETED,
            ].includes(session.status) && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 0C3.44772 0 3 0.447715 3 1V2H2C0.895431 2 0 2.89543 0 4V5V7V14C0 15.1046 0.89543 16 2 16H12C13.1046 16 14 15.1046 14 14V7V5V4C14 2.89543 13.1046 2 12 2H11V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V2H5V1C5 0.447715 4.55228 0 4 0ZM12 7H2L2 14H12V7ZM9 9H5V12H9V9Z"
                    fill="#5440F0"
                  />
                </svg>
                <p className={styles.session_details_text}>
                  Mentee in {session.mentee_profile.timezone}
                </p>
              </div>
            )}
          </div>
        </div>
        <button className={styles.details_button} onClick={toggleShowDetails}>
          Details
          <img
            width="15px"
            height="15px"
            src={UpBlueChevron}
            alt="up-blue-chevron"
            className={styles.chevron}
            style={{
              transform: show_details ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </button>
      </div>
      {show_details && (
        <div className={styles.details_container}>
          <div>
            <Socials socials={session?.mentee_profile.social} />
            <div className={styles.agenda}>
              <h4>AGENDA</h4>
              <p>{session.description}</p>
            </div>
          </div>
          {actions}
        </div>
      )}
    </div>
  );
};

export default SessionCard;