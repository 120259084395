import { Grid } from "react-loader-spinner";

import ConnectStripeAccount from "./ConnectStripeAccount";
import GoToStripeAccount from "./GoToStipeAccount";

import styles from "./EarningsWidget.module.css";

interface EarningWidgetProps {
  mentor_email: string;
  earnings: number;
  is_loading: boolean;
  stripe_loading: boolean;
  has_connected_account: boolean;
  goToStripe: () => void;
  connectStripe: () => void;
}

const EarningWidget: React.FC<EarningWidgetProps> = ({
  mentor_email,
  has_connected_account,
  earnings,
  is_loading,
  stripe_loading,
  goToStripe,
  connectStripe,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Mentor Earnings</h3>
      </div>
      <div className={styles.earnings}>
        {is_loading ? (
          <div style={{ paddingTop: "32px" }}>
            <Grid
              wrapperStyle={{ margin: "auto", display: "block" }}
              color="#5440F0"
              height={32}
              width={32}
            />
          </div>
        ) : (
          <>
            <h3 className={styles.title}>
              $
              <span>{Intl.NumberFormat("en-US").format(Number(earnings))}</span>
            </h3>
            <p className={styles.stats}>
              All time earnings from mentoring sessions.
              <br />
              (Earnings are calculated 2 business days after completing a
              session)
            </p>
          </>
        )}
      </div>
      {/* {has_connected_account ? (
        <GoToStripeAccount
          stripe_loading={stripe_loading}
          goToStripe={goToStripe}
        />
      ) : (
        <ConnectStripeAccount connectAccount={connectStripe} />
      )} */}
      <button
        className={styles.stripe}
        onClick={() => {
          const subject = encodeURIComponent(
            "Request for Mentor Earnings Payout"
          );
          const body = encodeURIComponent(
            "Hello MentorPass Team,\n\nI would like to request a payout of my mentor earnings.\n\nThank you!"
          );
          const mailtoLink = `mailto:team@mentorpass.co?subject=${subject}&body=${body}&cc=${encodeURIComponent(
            mentor_email
          )}`;
          window.location.href = mailtoLink;
        }}
      >
        Request Payout
      </button>
    </div>
  );
};

export default EarningWidget;
