export enum BookingAction {
    SELECT_MENTEE_REQUEST_SLOT = "[BOOKING] SELECT_MENTEE_REQUESTED_SLOT",
    REMOVE_MENTEE_REQUEST_SLOT = "[BOOKING] REMOVE_MENTEE_REQUESTED_SLOT",
    SET_PRIMARY_REQUEST_SLOT = "[BOOKING] SET_PRIMARY_REQUEST_SLOT",
    GET_MENTOR_AVALIBILITY_START = "[BOOKING] GET_MENTOR_AVALIBILITY_START",
    GET_MENTOR_AVALIBILITY_SUCCESS = "[BOOKING] GET_MENTOR_AVALIBILITY_SUCCESS",
    GET_MENTOR_AVALIBILITY_ERROR = "[BOOKING] GET_MENTOR_AVALIBILITY_ERROR",
    SET_INFO = "[BOOKING] SET_INFO",
    SET_ERROR_MESSAGE = "[BOOKING] SET_ERROR_MESSAGE",
    REQUEST_SESSION_START = "[BOOKING] REQUEST_SESSION_START",
    REQUEST_SESSION_SUCCESS = "[BOOKING] REQUEST_SESSION_SUCCESS",
    CLEAR_REQUEST_DATA = "[BOOKING] CLEAR_REQUEST_DATA",
}

interface BookingState {
    primary_request_slot?: string;
    mentee_request_slots: string[]
    date?: string;
    duration: number;
    okr?: string;
    description: string;
    availability: [];
    availability_is_loading: boolean;
    error_message?: string;
    session_request_is_submitting: boolean;
}

const isSlotPayload = (payload: any): payload is { slot: string } => {
    return typeof payload === 'object' && payload !== null && 'slot' in payload && typeof payload.slot === 'string';
}

export interface BookingCommand {
    type: BookingAction;
    payload?: Partial<BookingState | { slot: string }>;
}

const selectMenteeRequestSlotLogic = (
    state: BookingState = inital_state,
    command: BookingCommand
): BookingState => {
    if (!isSlotPayload(command.payload)) {
        return state;
    }
    const payload_slot: string = command.payload.slot
    // if the slot is already selected, remove it
    if (state.mentee_request_slots.includes(payload_slot)) {
        return {
            ...state,
            mentee_request_slots: state.mentee_request_slots.filter((slot) => slot != payload_slot)
        }
    }
    // if the slot is the first slot to be added, also set it as the primary_request_slot
    if (state.mentee_request_slots.length == 0) {
        return {
            ...state,
            primary_request_slot: payload_slot,
            mentee_request_slots: [payload_slot],
        }
    }
    // default to appending the slot the the existing list
    return {
        ...state,
        mentee_request_slots: [...state.mentee_request_slots, payload_slot],
    }
}

const inital_state: BookingState = {
    duration: 30,
    description: "",
    availability: [],
    date: undefined,
    mentee_request_slots: [],
    primary_request_slot: undefined,
    okr: undefined,
    availability_is_loading: false,
    error_message: undefined,
    session_request_is_submitting: false,
};

const bookingReducer = (
    state: BookingState = inital_state,
    command: BookingCommand
): BookingState => {
    switch (command.type) {
        case BookingAction.SET_INFO:
            return {
                ...state,
                ...command.payload,
            };
        case BookingAction.GET_MENTOR_AVALIBILITY_START:
            return {
                ...state,
                availability_is_loading: true,
            };
        case BookingAction.GET_MENTOR_AVALIBILITY_SUCCESS:
            return {
                ...state,
                ...command.payload,
                availability_is_loading: false,
            };
        case BookingAction.GET_MENTOR_AVALIBILITY_ERROR:
            return {
                ...state,
                availability_is_loading: false,
            };
        case BookingAction.SET_ERROR_MESSAGE:
            return {
                ...state,
                ...command.payload,
            };
        case BookingAction.CLEAR_REQUEST_DATA:
            return {
                ...inital_state,
            };
        case BookingAction.REQUEST_SESSION_START:
            return {
                ...state,
                session_request_is_submitting: true,
            };
        case BookingAction.REQUEST_SESSION_SUCCESS:
            return {
                ...state,
                session_request_is_submitting: false,
            };
        case BookingAction.SELECT_MENTEE_REQUEST_SLOT:
            return selectMenteeRequestSlotLogic(state, command);
        case BookingAction.REMOVE_MENTEE_REQUEST_SLOT:
            if (!isSlotPayload(command.payload)) {
                return state;
            }
            const payload_slot: string = command.payload.slot;
            return {
                ...state,
                mentee_request_slots: state.mentee_request_slots.filter((slot) => slot != payload_slot)
            }
        case BookingAction.SET_PRIMARY_REQUEST_SLOT:
            if (!isSlotPayload(command.payload)) {
                return state;
            }
            return {
                ...state,
                primary_request_slot: command.payload?.slot
            }
        default:
            return state;
    }
};

export default bookingReducer;
