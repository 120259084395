import { combineReducers } from "@reduxjs/toolkit";
import modalReducer from "./modal.reducer";
import chatReducer from "./chat.reducer";
import mentorReducer from "../dux/mentor/mentor.reducer";
import profileReducer from "./profile.reducer";
import alertReducer from "./alert.reducer";
import errorReducer from "./error.reducer";
import notificationReducer from "./notification.reducer";
import sessionDux from "../dux/session/sessions.dux";
import profileAPI from "../dux/profiles/api";
import sessionAPI from "../dux/session/api";
import bookingReducer from "../dux/booking/booking.reducer";
import mobileReducer from "./mobile.reducer";

const rootReducer = combineReducers({
  modalReducer,
  chatReducer,
  mentor: mentorReducer,
  profileReducer,
  alertReducer,
  errorReducer,
  notificationReducer,
  sessions: sessionDux,
  profiles: profileAPI.reducer,
  booking: bookingReducer,
  sessionsApi: sessionAPI.reducer,
  mobile: mobileReducer,
});

export default rootReducer;
