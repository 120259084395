import { useEffect, useState } from "react";
import DropdownSelector from "components/Atoms/DropdownSelector";
import InfoBox from "components/Molecules/InfoBox";
import OPTIONS from "constants/mentorPrices";
import LocationChoice from "components/Organisms/StripeLocationSelector/StripeLocationSelector.component";
import useMyProfile, { isMentor } from "hooks/useMyProfile";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import useStripeAccountDashboard from "hooks/mentor/account/useStripeAccountDashboard";
import { Stripe } from "components/Icons";
import { HourlyRate } from "entities/Mentor";

const Finances = ({ componentId }: { componentId: string }) => {
  const { profile } = useMyProfile();

  if (!isMentor(profile)) {
    throw new Error("Finance settings are only for mentors.");
  }

  const { update } = useUpdateMentor();

  const [hourlyRate, setHourlyRate] = useState({ title: "$100", value: 100 });

  const [locationSelectorModal, setLocationSelectionModal] = useState(false);

  const { is_loading: is_loading_stripe, goToDashboard } =
    useStripeAccountDashboard();

  const stripe_account_connected = profile?.stripe_account_connected;

  useEffect(() => {
    setHourlyRate({
      ...profile.hourlyRate,
    });
  }, [profile]);

  const changeHourlyRate = (rate: HourlyRate) => {
    update({ hourlyRate: rate });
  };

  return (
    <>
      <div className="section" id={componentId}>
        <div className="section-content finances">
          <div className="section-header">
            <h3>Finances</h3>
            {/* {!stripe_account_connected && (
              <button
                className="btn-small"
                disabled={stripe_account_connected}
                onClick={() => setLocationSelectionModal(true)}
              >
                Connect Stripe
              </button>
            )} */}
          </div>
          <DropdownSelector
            value={hourlyRate.value}
            key={hourlyRate.title}
            label="Hourly Rate"
            placeholder={hourlyRate.title}
            options={OPTIONS}
            onSelect={changeHourlyRate}
            dark
            outline={false}
          />
          {/* <hr className="border-black" /> */}
          {/* <InfoBox
            Icon={Stripe}
            label="Stripe Account"
            helperText="The best way to receive mentor payouts is through Stripe."
            buttonText={stripe_account_connected ? "Go to Stripe" : "Connect"}
            onClick={
              stripe_account_connected
                ? () => !is_loading_stripe && goToDashboard()
                : () => setLocationSelectionModal(true)
            }
          />*/}
        </div> 
      </div>
      {locationSelectorModal && (
        <LocationChoice close={() => setLocationSelectionModal(false)} />
      )}
    </>
  );
};

export default Finances;
