import { t } from 'xstate';
import * as Yup from 'yup';
import { Profile, ProfileQueryResult } from './Profile';
import { OKR, OKRQueryResult } from './OKR';

export enum ALLOWED_STATUS {
	REQUEST = "request",
	UPCOMING = "upcoming",
	RESCHEDULE = "reschedule",
	COMPLETED = "completed",
	EXPIRED = "expired",
	DECLINED = "declined",
	CANCELED = "canceled",
}

const ReviewSchema = Yup.object({
	nps: Yup.number(),
	best_quality: Yup.string(),
	public_feedback: Yup.string(),
	private_feedback: Yup.string(),
});

export const SessionSchema = Yup
	.object({
		to: Yup.string().required(),
		from: Yup.string().required(),
		slot: Yup.string(),
        primary_request_slot: Yup.string().required(),
        mentee_request_slots: Yup.array().of(Yup.string().required()).required(),
		reschedule_note: Yup.string(),
		duration: Yup.number().required(),
		okr: Yup.string().nullable(),
		title: Yup.string(),
		description: Yup.string(),
		attachments: Yup.array().of(Yup.object({})),
		createdAt: Yup.string().required(),
		cost: Yup.number().required(),
		location: Yup.string(),
		status: Yup
		.mixed<ALLOWED_STATUS>()
		.oneOf(Object.values(ALLOWED_STATUS)).required(),
		silence: Yup.boolean().default(false),
		videoRoomLink: Yup.string(),
		video_room_id: Yup.string(),
		calendarEventId: Yup.string(),
		calendarEventLink: Yup.string(),
		mentee_review: ReviewSchema.notRequired(),
		requestExpiringReminderSent: Yup.boolean().default(false),
	})
	.required();

export type CallRecording = {
	_id: string;
	video_id: string;
	ready: boolean;
	thumbnail_mini: string;
	thumbnail_big: string;
}

export type Review = Yup.InferType<typeof ReviewSchema>;

export type Session = Yup.InferType<typeof SessionSchema>;

export type SessionQueryResult = Session & { _id: string };

export type SessionQueryResultWithProfiles = SessionQueryResult & {
	to: ProfileQueryResult
	from: ProfileQueryResult
};

export type SessionQueryResultsWithDetails = SessionQueryResultWithProfiles & {
	okr: OKRQueryResult;
}