import { Grid } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import { Stat } from "hooks/mentor/usePerformanceStats";
import PerformanceLineItem from "components/Atoms/PerformanceLineItem";

import styles from "./PreformanceWidget.module.css";

interface IPerformanceWidgetProps {
  data: Stat[];
  isLoading: boolean;
}

const PerformanceWidget: React.FC<IPerformanceWidgetProps> = ({
  data,
  isLoading,
}) => {
  const navigate = useNavigate();

  const toLink = (link: string | undefined) => {
    if (!link) return;
    navigate(link);
  };

  return (
    <div data-testid="PerformanceWidget" className={styles.performanceWidget}>
      <div className={styles.performanceWidgetHeader}>
        <h3>Performance</h3>
      </div>
      <ul className={styles.performanceItemsList}>
        {isLoading || !data ? (
          <div
            data-testid="PerformanceWidget_loading"
            className={styles.loading}
          >
            <Grid
              wrapperStyle={{ margin: "auto", display: "block" }}
              color="#5440F0"
              height={32}
              width={32}
            />
          </div>
        ) : (
          data.map((d, i) => (
            <PerformanceLineItem
              key={`li-${i}`}
              title={d.title}
              value={d.value}
              help={d.help}
              onClick={d.link ? toLink.bind(null, d.link) : undefined}
            />
          ))
        )}
      </ul>
    </div>
  );
};

export default PerformanceWidget;
