import { createHeaders, API_URL } from "../../../utils/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const sessionAPI = createApi({
  reducerPath: "sessionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/session`,
    prepareHeaders: createHeaders,
  }),
  endpoints: (builder) => ({
    getAllSessionsTo: builder.query({
      query: () => "/all/to",
      transformResponse: (response) => response.sessions,
    }),
  }),
});

export default sessionAPI;
