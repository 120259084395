import { Session } from "entities/Session";
import { createHeaders, API_URL } from "../../../utils/api";


enum CRUD_METHOD {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
}

const sessionCrud =
  (method: CRUD_METHOD) =>
    async (token: string, id: string, payload: Partial<Session>) => {
      let config: RequestInit = {
        method,
        headers: createHeaders(token),
      };
      if (payload) {
        config["body"] = JSON.stringify(payload);
      }
      const response = await fetch(`${API_URL}/session/${id}`, config);

      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        throw Error(data.error);
      }
      return data;
    };

export default {
  getSession: sessionCrud(CRUD_METHOD.GET),
  postSession: sessionCrud(CRUD_METHOD.POST),
  putSession: sessionCrud(CRUD_METHOD.PUT),
  deleteSession: sessionCrud(CRUD_METHOD.DELETE),
};
