import React, { useEffect } from "react";
import dayjs from "dayjs";
import styles from "./MobileProposedTimes.module.css";
import { ThreeDots } from "react-loader-spinner";
import { Alert } from "antd";

interface Time {
    datetime: string;
    is_primary: boolean;
}

interface ProposedTimeProps {
    datetime: string;
    is_primary: boolean;
    onMarkPrimary: (datetime: string) => void;
    onRemoveTime: (datetime: string) => void;
}

const ProposedTime: React.FC<ProposedTimeProps> = ({
    datetime,
    is_primary,
    onMarkPrimary,
    onRemoveTime,
}) => {
    return (
        <div key={datetime} className={styles.proposed_time_container}>
            <input
                type="checkbox"
                checked={is_primary}
                onChange={() => onMarkPrimary(datetime)}
            />
            <span>{dayjs(datetime).format("h:mmA dddd, MMMM DD")}</span>
            <div
                onClick={() => onRemoveTime(datetime)}
                className={styles.remove_time_button}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                >
                    <path
                        d="M1.37106 0.5L0 1.87106L5.15445 7.02551L0.0255815 12.1544L1.34571 13.4745L6.47458 8.34564L11.6289 13.5L13 12.1289L7.84564 6.97458L12.9747 1.84557L11.6545 0.525437L6.52551 5.65445L1.37106 0.5Z"
                        fill="#66686A"
                    />
                </svg>
            </div>
        </div>
    );
};

interface ProposedTimesProps {
    times: Time[];
    is_submitting: boolean;
    error_message?: string;
    onMarkPrimary: (datetime: string) => void;
    onRemoveTime: (datetime: string) => void;
    onRequestSession: () => void;
}

const NO_SLOTS_REQUIRED = 3;

export const MobileProposedTime: React.FC<ProposedTimesProps> = ({
    times,
    is_submitting,
    error_message,
    onMarkPrimary,
    onRemoveTime,
    onRequestSession,
}) => {
    const primary_timeslot = times.find((time) => time.is_primary);

    const alternative_times = times.filter((time) => !time.is_primary);

    useEffect(() => {
        window.Intercom('update', {
            //@ts-ignore
            "hide_default_launcher": true
        });
        //@ts-ignore
        return () => window.Intercom("update", { hide_default_launcher: false });
    }, []);

    const handleMarkPrimary = (timeslot: string) => {
        onMarkPrimary(timeslot);
        const proposed_times_container = document.getElementById("proposed_times_container");
        if(proposed_times_container) {
            proposed_times_container.scrollLeft = 0;
        }
    }

    return (
        <div className={styles.proposed_times_mobile}>
            {times.length < 1 && (
                <>
                    <h3>Booking Details</h3>
                    <p>
                        Please select at least three alternative times that you’re available
                        to meet with your mentor.{" "}
                    </p>
                </>
            )}
            <div className={styles.times_container} id="proposed_times_container">
                {primary_timeslot && (
                    <div className={styles.proposed_time_wrapper}>
                        <h3>Primary datetime</h3>
                        <ProposedTime
                            is_primary={primary_timeslot?.is_primary}
                            datetime={primary_timeslot?.datetime}
                            onMarkPrimary={handleMarkPrimary}
                            onRemoveTime={onRemoveTime}
                        />
                    </div>
                )}
                {alternative_times.map((time) => (
                    <div className={styles.proposed_time_wrapper}>
                        <h3>Alternative datetime</h3>
                        <ProposedTime
                            is_primary={time?.is_primary}
                            datetime={time?.datetime}
                            onMarkPrimary={handleMarkPrimary}
                            onRemoveTime={onRemoveTime}
                        />
                    </div>
                ))}
            </div>
            <button
                onClick={onRequestSession}
                disabled={times.length < NO_SLOTS_REQUIRED}
                className={styles.submit_button}
            >
                {is_submitting ? (
                    <div style={{ marginTop: "-2px" }}>
                        <ThreeDots
                            wrapperStyle={{ margin: "auto", display: "block" }}
                            color="#FFF"
                            height={24}
                            width={32}
                        />
                    </div>
                ) : (
                    "Request Session"
                )}
            </button>
            {!!error_message && <Alert type="error" message={error_message} />}
        </div>
    );
};

export default MobileProposedTime;
