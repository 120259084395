import { useState } from "react";
import styles from "./BrowseMentors.module.css";
import Header from "components/Organisms/Header";
import MentorProfileCard, {
  MentorProfileCardData,
} from "components/Molecules/MentorProfileCard";
import { Grid } from "react-loader-spinner";
import MentorFilter from "components/Molecules/MentorFilters";
import MentorSearch from "components/Molecules/MentorSearch";
import RightSidebar from "components/Atoms/RightSidebar";
import IconButton from "components/Atoms/IconButton/IconButton.component";
import { CrossIcon } from "components/Icons";
import CollectionFilter, {
  CollectionItem,
} from "components/Molecules/CollectionFilter/CollectionFilter";
import { CategoryLabelProps } from "components/Atoms/CategoryLabel/CategoryLabel";

type BrowseMentorsProps = React.ComponentProps<typeof MentorFilter> & {
  mentor_collections: Omit<CategoryLabelProps, "selected" | "onClick">[];
  selected_collection?: string;
  is_loading: boolean;
  mentors: MentorProfileCardData[];
  filter_term: string;
  selectCollection: (collection: CollectionItem) => void;
  setFilterTerm: (value: string) => void;
};

const BrowseMentors = ({
  is_loading,
  filter_term,
  setFilterTerm,
  mentors,
  filters,
  options,
  mentor_collections,
  selected_collection,
  selectCollection,
  setPriceFilter,
  setSortOption,
  setSelectedSkill,
  toggleFavouritesFilter,
  clearFilters,
}: BrowseMentorsProps) => {
  const [open_sidebar, setOpenSidebar] = useState<boolean>(false);

  const closeSideBar = () => setOpenSidebar(false);

  return (
    <>
      <RightSidebar open={open_sidebar} close={closeSideBar}>
        <div>
          <IconButton
            icon={CrossIcon}
            className={styles.close_button}
            action={closeSideBar}
          />
        </div>
        <MentorFilter
          filters={filters}
          options={options}
          setPriceFilter={setPriceFilter}
          setSortOption={setSortOption}
          setSelectedSkill={setSelectedSkill}
          toggleFavouritesFilter={toggleFavouritesFilter}
          clearFilters={clearFilters}
        />
      </RightSidebar>
      <Header>
        <div className={styles.header}>
          <h3>Discover</h3>
          <div className={styles.desktop_search}>
            <MentorSearch
              id="mentor_search"
              search_term={filter_term}
              onChangeSearchTerm={setFilterTerm}
              launchFilters={() => setOpenSidebar(true)}
            />
          </div>
        </div>
      </Header>
      <div className={styles.page_container} id="mentor_container">
        <div className={styles.mobile_search}>
          <MentorSearch
            id="mentor_search"
            search_term={filter_term}
            onChangeSearchTerm={setFilterTerm}
            launchFilters={() => setOpenSidebar(true)}
          />
        </div>
        <div className={styles.mentor_collections}>
          <CollectionFilter
            id={"mentor_collections"}
            selected={selected_collection}
            collection={mentor_collections}
            onSelect={selectCollection}
          />
        </div>
        <div className={styles.mentor_container}>
          {mentors &&
            mentors.map((m, i) => (
              <MentorProfileCard key={`${m._id}-${i}`} mentor={m} />
            ))}
          {!is_loading && mentors.length === 0 && (
            <div className={styles.no_results}>
              <p>No results. Try changing your search settings</p>
              <button
                className="btn-dark"
                style={{ marginTop: "24px" }}
                onClick={clearFilters}
              >
                Clear Filters
              </button>
            </div>
          )}
          {is_loading && (
            <div className={styles.loading_spinner_container}>
              <Grid
                wrapperStyle={{
                  display: "block",
                  position: "fixed",
                }}
                color="#5440F0"
                height={48}
                width={48}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BrowseMentors;
