import classNames from "classnames/bind";

import styles from "./LargeRadioButtons.module.css";

interface ILargeRadioButtonsProps {
  helperText: string;
  value: string;
  checked?: boolean;
  valueLabel: string;
  onRadioBtnClick: (value: string) => void;
  name: string;
}

const cx = classNames.bind(styles);

const LargeRadioButtons: React.FC<ILargeRadioButtonsProps> = ({
  helperText,
  value,
  checked,
  valueLabel,
  onRadioBtnClick,
  name,
}) => {
  const containerStyles = cx({
    container: true,
    containerSelected: checked,
  });

  return (
    <div
      data-testid="LargeRadioButtons"
      className={containerStyles}
      onClick={onRadioBtnClick.bind(null, value)}
    >
      <div className={styles.content}>
        <input
          data-hj-allow
          id={value}
          name={name}
          type="radio"
          value={value}
          checked={checked}
          onChange={onRadioBtnClick.bind(null, value)}
        />
        <p className={styles.midText}>{valueLabel}</p>
        <p
          style={{
            textAlign: "center",
            padding: "0px",
            marginTop: "5px",
            fontSize: "12px",
          }}
        >
          {helperText}
        </p>
      </div>
    </div>
  );
};

export default LargeRadioButtons;
