import { z } from "zod";
import { ProfileQueryResult } from "./Profile";

enum Qualities {
  "Super Genius" = "Super Genius",
  Inspirational = "Inspirational",
  "Great Listner" = "Great Listner",
  "Insightful Questions" = "Insightful Questions",
  "Specific Advice" = "Specific Advice",
  "Extra Generous" = "Extra Generous",
}

const ContentSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  url: z.string().optional(),
})

export type Content = z.infer<typeof ContentSchema>

const HighlightSchema =
  z.object({
    title: z.string(),
    description: z.string(),
  });

export type Highlight = z.infer<typeof HighlightSchema>

const Skill = z.object({
  title: z.string().optional(),
  value: z.string()
});

export type Skill = z.infer<typeof Skill>

const ProfileVideoSchema = z.object({
  url: z.string(),
  mimetype: z.string()
});

export type ProfileVideo = z.infer<typeof ProfileVideoSchema>


export enum WeekDay {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export const defaultDailyAvailability = {
  start: {
    alt: "09:00",
    title: "9:00AM",
    value: 540,
  },
  end: {
    alt: "17:00",
    title: "5:00PM",
    value: 1020,
  },
};

export const defaultWeeklyAvailability = {
  monday: [defaultDailyAvailability],
  tuesday: [defaultDailyAvailability],
  wednesday: [defaultDailyAvailability],
  thursday: [defaultDailyAvailability],
  friday: [defaultDailyAvailability],
  saturday: [],
  sunday: [],
};

export const defaultAvailabilityConfiguration = {
  weeklyLimit: 14,
  halfHour: true,
  hour: true,
};

export const defaultSocialMedia = {
  twitter: null,
  linkedin: null,
  tiktok: null,
  instagram: null,
  crunchbase: null,
  github: null,
  angellist: null,
};

export const AvailabilityConfigurationSchema = z.object({
  weeklyLimit: z.number(),
  halfHour: z.boolean(),
  hour: z.boolean(),
});

const TimeSchema = z.object({
  alt: z.string(),
  title: z.string(),
  value: z.number(),
});

const AvailabileDaySchema = z.object({
  start: TimeSchema,
  end: TimeSchema,
});

const AvailabileWeekSchema = z.object({
  [WeekDay.MONDAY]: z.array(AvailabileDaySchema),
  [WeekDay.TUESDAY]: z.array(AvailabileDaySchema),
  [WeekDay.WEDNESDAY]: z.array(AvailabileDaySchema),
  [WeekDay.THURSDAY]: z.array(AvailabileDaySchema),
  [WeekDay.FRIDAY]: z.array(AvailabileDaySchema),
  [WeekDay.SATURDAY]: z.array(AvailabileDaySchema),
  [WeekDay.SUNDAY]: z.array(AvailabileDaySchema),
});

const HourlyRateSchmea = z.object({
  title: z.string(),
  value: z.number(),
  tokens: z.number(),
});


const defaultHourlyRate = {
  title: "$200",
  tokens: 24,
  value: 24000,
};

interface Review {
  reviewer: {
    name: string;
    avatarImage: string;
  };
  rating: number;
  comment: string;
  bestQuality: Qualities;
  created_at: string;
}

export type AvailableDay = z.infer<typeof AvailabileDaySchema>;

export type AvailableWeek = Record<WeekDay, AvailableDay[]>;

export type HourlyRate = z.infer<typeof HourlyRateSchmea>;

export type AvailabilityConfiguration = z.infer<
  typeof AvailabilityConfigurationSchema
>;

export type MentorQueryResult = ProfileQueryResult & {
  availability: AvailableWeek,
  availabilityDetails: AvailabilityConfiguration,
  hourlyRate: HourlyRate,
  content: Content[];
  skills: Skill[];
  highlights: Highlight[];
  reviews: Review[];
  profileVideo?: ProfileVideo;
  stripe_account_connected: boolean;
  calendar_connected: boolean;
  tags: string[];
  charity?: {
    name: string;
    link: string;
    percentage: number
  };
};

export const isMentor = (
  profile: ProfileQueryResult
): profile is MentorQueryResult => profile.type == "mentor";
