import { useState } from "react";
import PerformanceWidget from "../../Molecules/PerformanceWidget";
import EarningsWidget from "components/Molecules/EarningsWidget";
import useEarningsData from "hooks/mentor/account/useEarningsData";
import usePerformanceStats from "hooks/mentor/account/usePerformanceStats";
import useStripeAccountDashboard from "hooks/mentor/account/useStripeAccountDashboard";
import useMyProfile from "hooks/useMyProfile";
import { isMentor } from "entities/Mentor";
import StripeLocationSelector from "components/Organisms/StripeLocationSelector/StripeLocationSelector.component";
import { useAuth0 } from "@auth0/auth0-react";

const MentorDashboardStats: React.FC = () => {
  const { profile } = useMyProfile();
  const { user } = useAuth0();
  if (!isMentor(profile)) {
    throw new Error("Finance settings are only for mentors.");
  }
  const { data: earnings, isFetched: earnings_are_ready } = useEarningsData(30);
  const { data: performance, isFetched: performance_is_ready } =
    usePerformanceStats(0);
  const { is_loading: is_loading_stripe, goToDashboard } =
    useStripeAccountDashboard();

  const [location_selection_open, setLocationSelectionOpen] =
    useState<boolean>(false);

  return (
    <div data-testid="MentorDashboardStats" className="o-mds">
      <div>
        <PerformanceWidget
          data={performance || []}
          isLoading={!performance_is_ready}
        />
        <EarningsWidget
          mentor_email={user?.email || ""}
          earnings={earnings?.earnings || 0}
          is_loading={!earnings_are_ready}
          stripe_loading={is_loading_stripe}
          has_connected_account={!!profile?.stripe_account_connected}
          goToStripe={goToDashboard}
          connectStripe={() => setLocationSelectionOpen(true)}
        />
      </div>
      {location_selection_open && (
        <StripeLocationSelector close={() => setLocationSelectionOpen(false)} />
      )}
    </div>
  );
};

export default MentorDashboardStats;
